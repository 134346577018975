import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Meta from '../components/Meta'
import Img from 'gatsby-image'
import { Parallax } from 'react-scroll-parallax'
import { siteMetadata } from '../../gatsby-config'

const Services = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Zakres usług" />
      <Parallax className="parallax-image" y={[-10, 20]} tagOuter="figure">
        <Img fluid={data.file.childImageSharp.fluid} />
      </Parallax>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center my-5">Zakres usług</h1>
            <p>
              <b>
                Biuro oferuje pełen zakres usług księgowych, a w szczególności:
              </b>
            </p>
            <ul>
              <li>prowadzenie ksiąg rachunkowych</li>
              <li>prowadzenie książki przychodów i rozchodów</li>
              <li>prowadzenie i rozliczanie ryczałtu ewidencjonowanego</li>
              <li>
                prowadzenie spraw kadrowych, płacowych oraz pełna obsługa w
                zakresie ZUS
              </li>
              <li>prowadzenie ewidencji do celów podatku VAT</li>
              <li>
                prowadzenie ewidencji środków trwałych, wyposażenia oraz
                wartości niematerialnych i&nbsp;prawnych
              </li>
              <li>
                rozliczanie i sporządzanie deklaracji podatkowych VAT, PIT, CIT
              </li>
              <li>rozliczanie i sporządzanie deklaracji ZUS, PFRON</li>
              <li>
                sporządzanie rocznych zeznań podatkowych dla osób fizycznych
              </li>
              <li>
                sporządzanie sprawozdań finansowych spółki zgodnie z polskimi
                standardami rachunkowości
              </li>
              <li>sporządzanie sprawozdawczości GUS</li>
              <li>
                sporządzanie miesięcznych zestawień i analiz dotyczących
                działalności firmy w formie uzgodnionej z klientem
              </li>
              <li>przygotowywanie raportów na potrzeby zarządzania</li>
              <li>
                rozliczanie delegacji służbowych krajowych i zagranicznych
              </li>
              <li>reprezentacja firmy przed Urzędem Skarbowym i ZUS</li>
              <li>współpraca z audytorami oraz organami kontroli skarbowej</li>
              <li>
                bieżące doradztwo w zakresie prowadzenia działalności
                gospodarczej
              </li>
            </ul>
            <p>
              <b>Oferujemy także usługi w zakresie:</b>
            </p>
            <ul>
              <li>
                pomocy przy założeniu spółki oraz jej rejestracji w odpowiednich
                urzędach (KRS, US, GUS, ZUS)
              </li>
              <li>
                pomocy przy zakładaniu i likwidowaniu działalności gospodarczej
                prowadzonej jednoosobowo
              </li>
              <li>sporządzania sprawozdawczości NBP i INTRASTAT</li>
              <li>
                prowadzenia rozliczeń i sprawozdawczości dla stowarzyszeń i
                fundacji
              </li>
              <li>
                pomocy w zmianie formy prowadzenia działalności gospodarczej
                oraz dokonywaniu zmiany danych podmiotu
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Services

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "index/fot-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
